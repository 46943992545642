/* eslint-disable */
import { isChatBotLiveChatEnabled } from "src/utils";
import { IExtendedWindow } from "src/types";
import { azureAppConfigVar } from "src/cache";

export const initChatBot = () => {
  ((w: IExtendedWindow) => {
    if (isChatBotLiveChatEnabled()) {
      w.__be = w.__be || {};
      w.__be.id = azureAppConfigVar().CHAT_BOT_LICENSE_NUMBER;

      const chatBotScript = document.createElement("script");
      const existingScript = document.getElementsByTagName("script")[0];

      chatBotScript.type = "text/javascript";
      chatBotScript.async = true;
      chatBotScript.src =
        ("https:" == document.location.protocol ? "https://" : "http://") +
        "cdn.chatbot.com/widget/plugin.js";

      if (existingScript?.parentNode) {
        existingScript.parentNode.insertBefore(chatBotScript, existingScript);
      }
    }
  })(window as unknown as IExtendedWindow);
};
