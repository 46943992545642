import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { initLiveChat } from "src/lib/livechat";
import { initChatBot } from "src/lib/chatbot";
import { IExtendedWindow } from "src/types";
import {
  takeCandidateData,
  takeCaseCode,
  takeCaseId,
  takeIsLiveChatEnabledForClient
} from "modules/case/selectors";

export const useChatWidget = () => {
  const caseId = useSelector(takeCaseId);
  const caseCode = useSelector(takeCaseCode);
  const isLiveChatEnabledForClient = useSelector(
    takeIsLiveChatEnabledForClient
  );
  const candidateData = useSelector(takeCandidateData);

  const userAttributes = useMemo(
    () => ({
      caseId,
      caseCode,
      email: candidateData?.emailAddress || "",
      userName: `${candidateData?.firstName || ""} ${
        candidateData?.lastName || ""
      }`
    }),
    [
      caseId,
      caseCode,
      candidateData?.emailAddress,
      candidateData?.firstName,
      candidateData?.lastName
    ]
  );

  const onLiveChatWidgetReady = useCallback(() => {
    const w = window as unknown as IExtendedWindow;

    w.LiveChatWidget.call("set_session_variables", userAttributes);
    w.LiveChatWidget.call("set_customer_name", userAttributes.userName);
    w.LiveChatWidget.call("set_customer_email", userAttributes.email);
  }, [userAttributes]);

  const onChatBotWidgetReady = useCallback(() => {
    const w = window as unknown as IExtendedWindow;

    w.BE_API?.setUserAttributes(userAttributes);
    w.BE_API?.setSessionAttributes(userAttributes);
  }, [userAttributes]);

  const initWidgetEvents = useCallback(() => {
    const w = window as unknown as IExtendedWindow;
    w.BE_API = w.BE_API || {};

    if (w.LiveChatWidget) {
      w.LiveChatWidget.on("ready", onLiveChatWidgetReady);
    }

    if (w.BE_API) {
      w.BE_API.onLoad = onChatBotWidgetReady;
    }
  }, [onLiveChatWidgetReady, onChatBotWidgetReady]);

  useEffect(() => {
    const w = window as unknown as IExtendedWindow;
    const isChatAlreadyInitialized = Boolean(w.LiveChatWidget || w.BE_API);

    if (isLiveChatEnabledForClient === null || isChatAlreadyInitialized) {
      return;
    }

    if (isLiveChatEnabledForClient) {
      initLiveChat();
    } else {
      initChatBot();
    }

    initWidgetEvents();
  }, [initWidgetEvents, onChatBotWidgetReady, isLiveChatEnabledForClient]);
};
