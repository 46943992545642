import React, { useCallback, useState } from "react";
import { BUTTON_TYPE, Link } from "@security-watchdog/sw-ui-kit";
import googleAnalytics from "src/lib/googleAnalytics";
import { CookiesValue } from "src/types";
import * as s from "./styles";
import { CookieBanner, Icon, Button } from "@security-watchdog/ui-components";
import { isClarityEnabled } from "src/utils";
import { ROUTES } from "src/constants";
import { useMicrosoftClarityContext } from "src/lib/microsoftClarity/context";
import { getConsentCookie, setConsentCookie } from "src/utils/cookies";

const DisclaimerCookieComponent: React.FC = () => {
  const { init } = googleAnalytics();

  const { setClarityConsent } = useMicrosoftClarityContext();

  const [isShowDisclaimer, setIsShowDisclaimer] =
    useState<boolean>(!getConsentCookie());

  const setCookies = useCallback(
    (value: CookiesValue) => (): void => {
      setConsentCookie(value);
      setIsShowDisclaimer(false);

      if (value === CookiesValue.Accept) {
        init();
        setClarityConsent();
      }
    },
    [init, setClarityConsent]
  );

  if (isClarityEnabled()) {
    return (
      <CookieBanner
        isOpen={isShowDisclaimer}
        icon={<Icon size={24} name="cookie" className="color-icon-active" />}
        actions={
          <Button onClick={setCookies(CookiesValue.Accept)}>
            I understand
          </Button>
        }
        text={
          <span>
            Security Watchdog uses cookies which are essential for the site to
            work. We’d also like to set optional analytics cookies to help us
            improve our services. Any data collected is anonymised. For more
            information please see our{" "}
            <Link
              isExternal
              to={`${ROUTES.COOKIES_PAGE}${window.location.search}`}
            >
              Cookies page
            </Link>
            .
          </span>
        }
      />
    );
  }

  return isShowDisclaimer ? (
    <s.ContainerDisclaimer>
      <s.ContainerTextInfo>
        <s.PrimaryText>Use of cookies</s.PrimaryText>
        <s.SecondaryText>
          Security Watchdog uses cookies which are essential for the site to
          work. We’d also like to set optional analytics cookies to help us
          improve our services. Any data collected is anonymised. For more
          information please see our Cookies page
        </s.SecondaryText>
      </s.ContainerTextInfo>
      <s.ContainerButton>
        <s.CustomButton
          buttonType={BUTTON_TYPE.Link}
          onClick={setCookies(CookiesValue.Decline)}
        >
          Decline
        </s.CustomButton>
        <s.CustomButton
          buttonType={BUTTON_TYPE.Primary}
          onClick={setCookies(CookiesValue.Accept)}
        >
          Accept
        </s.CustomButton>
      </s.ContainerButton>
    </s.ContainerDisclaimer>
  ) : null;
};

export const DisclaimerCookie = React.memo(DisclaimerCookieComponent);
