import { COOKIES_CONSENT_LC_KEY } from "src/constants/system";
import { CookiesValue } from "src/types";

export const getConsentCookie = (): string | null =>
  localStorage.getItem(COOKIES_CONSENT_LC_KEY);

export const setConsentCookie = (value: CookiesValue): void =>
  localStorage.setItem(COOKIES_CONSENT_LC_KEY, value);

export const isConsentCookieAccepted = (): boolean =>
  getConsentCookie() === CookiesValue.Accept;
