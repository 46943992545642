import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { isClarityEnabled } from "src/utils";
import { azureAppConfigVar } from "src/cache";
import { useSelector } from "react-redux";
import { takeCandidateData } from "modules/case/selectors";
import Clarity from "@microsoft/clarity";
import { isConsentCookieAccepted } from "src/utils/cookies";
import { MicrosoftClarityContext } from "src/lib/microsoftClarity/context";

const Component: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isClarityInitialized, setIsClarityInitialized] =
    useState<boolean>(false);

  const candidate = useSelector(takeCandidateData);

  const email = candidate?.emailAddress || "";

  const setClarityConsent = useCallback(
    (forceInitialization = false) => {
      if (
        isConsentCookieAccepted() &&
        (forceInitialization || isClarityInitialized)
      ) {
        Clarity.consent(true);
      }
    },
    [isClarityInitialized]
  );

  useEffect(() => {
    if (isClarityEnabled() && email && !isClarityInitialized) {
      Clarity.init(azureAppConfigVar().CLARITY_KEY);
      Clarity.identify(email, undefined, undefined, email);
      setIsClarityInitialized(true);
      setClarityConsent(true);
    }
  }, [email, isClarityInitialized, setClarityConsent]);

  const contextValue = useMemo(
    () => ({ setClarityConsent }),
    [setClarityConsent]
  );

  return (
    <MicrosoftClarityContext.Provider value={contextValue}>
      {children}
    </MicrosoftClarityContext.Provider>
  );
};

Component.displayName = "MicrosoftClarity";

export const MicrosoftClarity = memo(Component);
