import { createContext, useContext } from "react";

type MicrosoftClarityContextType = {
  setClarityConsent: () => void;
};

const microsoftClarityContextInitialValue = {
  setClarityConsent: () => null
};

export const MicrosoftClarityContext =
  createContext<MicrosoftClarityContextType>(
    microsoftClarityContextInitialValue
  );

export const useMicrosoftClarityContext = () =>
  useContext(MicrosoftClarityContext);
